/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    suitcase: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 5a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7A.5.5 0 016 5m2 0a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7A.5.5 0 018 5m2 0a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7A.5.5 0 0110 5"/><path pid="1" d="M6.5 0a.5.5 0 00-.5.5V3H5a2 2 0 00-2 2v8a2 2 0 001.031 1.75A1.003 1.003 0 005 16a1 1 0 001-1h4a1 1 0 101.969-.25A2 2 0 0013 13V5a2 2 0 00-2-2h-1V.5a.5.5 0 00-.5-.5zM9 3H7V1h2zm3 10a1 1 0 01-1 1H5a1 1 0 01-1-1V5a1 1 0 011-1h6a1 1 0 011 1z"/>',
    },
});
